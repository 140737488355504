<template>
  <div class="app-container">
    <div v-show="dataViewShow">
      <!-- 查询区域 -->
      <div>
        <el-form layout="inline">
          <el-row :gutter="24">
            <el-col :md="6" :sm="8">
              <el-button @click="selectDataListButton()">查询</el-button>
            </el-col>
            <el-col :md="6" :sm="8"> </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin: 9px 2px">
        <el-button
          type="success"
          icon="el-icon-add"
          size="min"
          @click="addButton"
          >新增</el-button
        >

        <el-button
          type="success"
          icon="el-icon-add"
          size="min"
          @click="delectButton"
          disabled=""
          >删除</el-button
        >
        <el-button
          type="success"
          icon="el-icon-add"
          size="min"
          @click="selectButton(this.id)"
          disabled=""
          >查看</el-button
        >
      </el-row>

      <!--     列表区域   -->
      <el-table :data="dataColumnList">
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="min"
              @click="editButton(scope.row)"
              >编辑</el-button
            >
            <!-- <el-button
            size="mini"
            type="text"
            icon="el-icon-download"
            @click="downloadResWordButton(scope.row)"
            >点击生成</el-button
          > -->
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="selectButton(scope.row)"
              >查看</el-button
            ><el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="reimburseDataButton(scope.row)"
              >查看报销</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="personButton(scope.row)"
              >人员人天登记</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="selectDayAndMoneyViewButton(scope.row)"
              >查看视图</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="selectWorkViewButton(scope.row)"
              >查看工作视图</el-button
            >
            
            <!-- <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="selectButton(scope.row)"
            disabled=""
            >获取报销</el-button
          > -->
          </template>
        </el-table-column>
        <el-table-column label="项目编码" align="center" prop="code" />
        <el-table-column label="项目名称" align="center" prop="name" />
        <el-table-column label="开始时间" align="center" prop="startTime" />
        <el-table-column label="结束时间" align="center" prop="endTime" />
      </el-table>

      <pagination
        :total="queryParams.total"
        :page-size.sync="queryParams.pageSize"
        :current-page.sync="queryParams.currentPage"
      />
    </div>
    <addEdit ref="addEditFrom" />
    <reimburseData ref="reimburseDataFrom" />
    <personIndex ref="personIndexFrom" />
    <dataViewsInfo ref="dataViewsInfoForm" v-show="!dataViewShow&&!dataViewsInfoShow" />
    <dataWorkViewsInfo ref="dataWorkViewsInfoForm" v-show="!dataViewShow&&!dataViewsWorkInfoShow"/>
  </div>
</template>


<script>
import { downLoad } from "@/utils/zipdownload";
import reimburseData from "./modules/reimburseData";
import addEdit from "./modules/addEditproject";
import { listprojectApi } from "@/api/work/project/project";
import dataViewsInfo from "../dataViews/index.vue";
import dataWorkViewsInfo from "../dataViews/workView.vue";
import personIndex from "./modules/personIndex";
export default {
  components: {
    addEdit,
    reimburseData,
    dataWorkViewsInfo,
    personIndex,
    dataViewsInfo,
  },
  data() {
    return {
      // 选中数组
      questionnaireId: "",
      radio: null,
      dataViewShow:true,
      dataViewsInfoShow: true,
      dataViewsWorkInfoShow:true,
      ids: null,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.selectDataListButton();
  },
  methods: {
    selectDataListButton() {
      listprojectApi().then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    // getCurrentRow(row) {
    //   // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
    //   this.ids = row.code;
    //   this.optFrom = row;
    // },

    getCurrentRow(row) {
      //this.questionnaireId 为上一次存储的id与这一次比对判断是否和上次点击的一致
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.code;
        this.templateSelection = row;
      }
    },

    addButton() {
      this.$refs.addEditFrom.show(null, 1);
    },
    editButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      this.$refs.addEditFrom.show(row.code, 2);
    },
    delectButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    selectButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      this.$refs.addEditFrom.show(row.code, 3);
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.selectDataListButton();
    },
    reimburseDataButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      this.$refs.reimburseDataFrom.show(row, 1);
    },
    downloadResWordButton(row) {
      downLoad("/xun/work/projectReimburse/downloadResWord", "xun");
    },
    personButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      this.$refs.personIndexFrom.show(row, 1);
    },
    selectViewButton(rwo) {
      this.dataViewShow=false
      this.dataViewsInfoShow = !this.dataViewsInfoShow;
    },
    selectWorkViewButton(row) {
      this.dataViewShow=false
      this.dataViewsWorkInfoShow = !this.dataViewsWorkInfoShow;
      this.$refs.dataWorkViewsInfoForm.show(row,1);
    },
    selectDayAndMoneyViewButton(row) {
      this.dataViewShow=false
      this.dataViewsWorkInfoShow = !this.dataViewsWorkInfoShow;
      this.$refs.dataWorkViewsInfoForm.show(row,2);
    },
  },
};
</script>



